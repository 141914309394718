/* src/PasswordManager.css */

/* Общие стили */
body {
    font-family: 'Roboto', sans-serif;
    background-color: #f4f7f9;
    color: #333;
    margin: 0;
    padding: 0;
}

.AppRoot {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px;
    background-color: #f4f7f9;
}

.form-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: #fff;
    padding: 24px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.input-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.add-password-btn {
    margin-top: 12px;
    padding: 14px;
    background-color: #4CAF50;
    color: white;
    font-size: 16px;
    border-radius: 8px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 100%;
}

.add-password-btn:hover {
    background-color: #45a049;
}

.password-list {
    margin-top: 24px;
}

.password-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 16px;
    margin-bottom: 12px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    width: 100%;
}

.password-item:hover {
    transform: translateY(-5px);
}

.password-info {
    display: flex;
    flex-direction: column;
}

.service-name {
    font-size: 18px;
    font-weight: bold;
    color: #333;
}

.category-text {
    font-size: 14px;
    color: #888;
}

.show-password-btn {
    background-color: #008CBA;
    color: white;
    padding: 8px 16px;
    font-size: 14px;
    border-radius: 5px;
    border: none;
}

.show-password-btn:hover {
    background-color: #007bb5;
}

button {
    cursor: pointer;
}

input {
    padding: 12px;
    font-size: 14px;
    border-radius: 8px;
    border: 1px solid #ccc;
    width: 100%;
}

input:focus {
    outline: none;
    border-color: #4CAF50;
}

/* Заголовки и текстовые блоки */
h1, .password-list, .form-container .text {
    text-align: center;
    width: 100%;
}

h1 {
    font-size: 24px;
    margin-bottom: 20px;
}

.text {
    font-size: 16px;
    margin-top: 8px;
    color: #555;
    text-align: center;
}

/* Адаптивность */
@media (max-width: 768px) {
    .form-container {
        gap: 12px;
        padding: 16px;
        width: 90%;
        margin-top: 16px;
    }

    .input-group input {
        font-size: 14px;
        padding: 10px;
    }

    .add-password-btn {
        padding: 12px;
        font-size: 14px;
    }

    .password-item {
        flex-direction: column;
        padding: 12px;
        width: 100%;
    }

    .password-info {
        margin-bottom: 8px;
    }

    .password-list {
        margin-top: 16px;
    }
}

@media (max-width: 480px) {
    .form-container {
        gap: 12px;
        padding: 12px;
        width: 100%;
    }

    .input-group input {
        font-size: 13px;
        padding: 8px;
    }

    .add-password-btn {
        padding: 10px;
        font-size: 12px;
    }

    .password-item {
        flex-direction: column;
        padding: 10px;
        width: 100%;
    }

    .password-info {
        margin-bottom: 6px;
    }

    .password-list {
        margin-top: 12px;
    }
}
